/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    $( '.open-close .opener').on('click', function() {
        $( this ).next().slideToggle( "slow" );
        return false;
    });

    $( '.content-form .opener').on('click', function() {
        $( '.content-form .open-close .slide-box' ).slideToggle( "slow" );
        return false;
    });

    function calculateForm() {
        var MonthlySales = 0;
        $('#CAT_Custom_1189313').each(function(){
            MonthlySales = $(this).val();
        });
        var anualSales = $('#CAT_Custom_1189330').val(MonthlySales*12);
        var procent = $('#CAT_Custom_1189353').val();
        if (!isNaN(procent) && procent.length !== 0) {
            procent =0;
        }


        var sum = 0;
        $(".monthly-express").each(function() {
            if (!isNaN(this.value) && this.value.length !== 0) {
                sum += parseFloat(this.value);
                var procentRes = $('#CAT_Custom_1189353').val(((sum/MonthlySales)*100));

            }
        });
        var total = $("#CAT_Custom_1189340").val(sum.toFixed());

        $('.content-form .btn').on("click", function(){
            var resultSales = parseFloat($('#CAT_Custom_1189313').val());
            resultSales = resultSales.toFixed(3)*12;
            var anualSales1 = $('.anual-sales1').text("$"+resultSales);
            var cogsAsRes = (resultSales*($('#CAT_Custom_1189353').val()/100))*(-1);
            var lessCogs = $('.less-cogs1').text("$"+cogsAsRes);
            var grossProfit = resultSales + cogsAsRes;
            var grossProfitRes = $('.gross-profit1').text("$"+grossProfit);


            var resultSales2 = resultSales;
            resultSales2 = resultSales2.toFixed(3)*2;
            $('.anual-sales2').text("$"+resultSales2);
            var cogsAsRes2 = cogsAsRes*2;
            var lessCogs2 = $('.less-cogs2').text("$"+cogsAsRes2);

            var pluseVend = 12*($("#CAT_Custom_1189339").val())*($("#CAT_Custom_1189354").val()/100);
            var pluseVendRes = $('.pluse-vend').text("$"+pluseVend);

            var factors = (($("#CAT_Custom_1189330").val()*2)*($("#CAT_Custom_1189355").val())/100)*(-1);
            var factorsRes = $('.factors').text("$"+factors);

            var grossProfit2 = resultSales2 + cogsAsRes2 + pluseVend + factors;
            var grossProfit2Res = $('.gross-profit2').text("$"+grossProfit2);
        });
    }
    calculateForm();
    $(".monthly-express, #CAT_Custom_1189313").on("keydown keyup", function() {
        calculateForm();
    });
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
